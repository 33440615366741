.main {
  height: 100vh;
  overflow: hidden;
}
.containerr {
  margin: auto;
}
.continerr>p {
  font-size: 10px;
  padding-left: 10px;
}
.contentt {
  display: grid;
  grid-template-columns: 4fr 2fr;
}
.imgg {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c9d6ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e2e2e2,
    #c9d6ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: url(/public/bg.svg) center,
    linear-gradient(159.02deg, #0f123b 14.25%, #090d2e 56.45%, #020515 86.14%);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bodyy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    159.02deg,
    #0f123b 14.25%,
    #090d2e 56.45%,
    #020515 86.14%
  );
  height: 100vh;
}
.logoo {
  position: absolute;
  top: 20px;
  right: 20px;
}
.form {
  width: 400px;
}
.loginTitle {
  text-align: center;
  color: #fff;
  font-size: 25px;
}

.footer {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  width: 400px;
  flex-direction: column;
}

.emaLogo {
  width: 150px;
  text-align: center;
  padding-bottom: 10px;
}
