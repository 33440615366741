@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

html,
body,
#root .app .content {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  position: relative;
}
