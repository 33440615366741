body {
  background: url(/public/bg.svg), url(/public/earth.svg) no-repeat,
    linear-gradient(159.02deg, #0f123b 14.25%, #090d2e 56.45%, #020515 86.14%),
    repeat;
  background-position: center center, right center, center;
}

.apexcharts-xaxis-label {
  cursor: pointer;
}

.ant-picker-input>input {
  color: #fff !important;
}

.ant-table-filter-dropdown {
  background-color: #1a1f37 !important;
  color: white !important;
}

.ant-dropdown-menu:empty::after {
  color: white !important;
}

.ant-table-filter-dropdown-search-input input {
  background: whitesmoke !important;
}

.ant-modal-close-x {
  color: white;
}

.cont {
  max-width: 1865px;
  margin: 0 auto;
}

.card:hover {
  border: 1px solid rgba(215, 222, 234, 1);
}

.card:hover p {
  color: white;
}

.card:hover .img {
  background: rgba(8, 120, 255, 1);
}

.card:hover .attention {
  background: #e31a1a66;
}

.right {
  border-right: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg,
      rgba(224, 225, 226, 0) 0%,
      #e0e1e2 47.22%,
      rgba(224, 225, 226, 0.15625) 94.44%);
  height: 100%;
  margin-right: 10px;
}

.borde {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg,
      rgba(224, 225, 226, 0) 0%,
      #e0e1e2 47.22%,
      rgba(224, 225, 226, 0.15625) 94.44%);
}

.icon {
  background-color: #1a1f37;
}

.active .icon {
  background-color: #004dd9;
}

.indi {
  width: 4px;
  height: 24px;
  border-radius: 2px 0px 0px 2px;
  position: absolute;
  right: 14px;
  display: none;
  background: #fff;
}

s .active .indi {
  display: flex;
}

.active .collapsed {
  background-color: #0075ff;
}

.active {
  font-size: 14px;
  font-weight: 700;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}